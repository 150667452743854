export enum FieldTypes {
  TypeInput = "input",
  TypeSelect = "select",
  TypeRadio = "radio",
}

export enum ProductFields {
  Title = "Title",
  ProductTypeID = "ProductTypeID",
  IDStatus = "IDStatus",
  StockStatus = "StockStatus",
  rootPath = "rootPath",
  productID = "productID",
  ownerGuid = "ownerGuid",
  hasViewerRole = "hasViewerRole",
  Metadata = "Metadata",
}

export enum RightsFields {
  Name = "Name",
  Description = "Description",
  GeofencingTypeID = "GeofencingTypeID",
  SelectedRegions = "SelectedRegions",
  BlacklistingAllowed = "BlacklistingAllowed",
  VisibilityTypeID = "VisibilityTypeID",
  ExtractionTypeID = "ExtractionTypeID",
}

export enum RightsRadioBtnFields {
  GeofencingTypeIDOptionValue1 = "Include regions",
  GeofencingTypeIDOptionValue2 = "Exclude regions",
  BlacklistingAllowedOptionValue0 = "Disabled",
  BlacklistingAllowedOptionValue1 = "Allowed",
  VisibilityTypeIDOptionValue1 = "Public: All can see the human readable vault names",
  VisibilityTypeIDOptionValue2 = "Network: Only the direct relationships can see the human readable vault names (rest wallet address)",
  VisibilityTypeIDOptionValue3 = "Private: No one can see the human readable vault names (only wallet address)",
  ExtractionTypeIDOptionValue1 = "Transactional and bulk: both options can.",
  ExtractionTypeIDOptionValue2 = "Bulk: digital assets can only be taken out in bulk.",
  ExtractionTypeIDOptionValue3 = "Transactional: digital assets can only be taken out per one",
}

export enum AssetInsertFields {
  ProductID = "ProductID",
  AgreementID = "AgreementID",
  KeyProviderID = "KeyProviderID",
  ownerID = "ownerID",
  Price = "Price",
  CurrencyCode = "CurrencyCode",
  RegionCode = "RegionCode",
  PreOrderCount = "PreOrderCount",
  ProductTitle = "ProductTitle",
  SerialCount = "SerialCount",
}

export enum AssetExtractResourceFields {
  content = "content",
  ownerGuid = "ownerGuid",
  userGuid = "userGuid",
  userID = "userID",
  fixedProductID = "fixedProductID",
  rootPath = "rootPath",
  isEmailFlow = "isEmailFlow",
  isFixedRequestFlow = "isFixedRequestFlow",
  selectedPeriod = "selectedPeriod",
  mailUrl = "mailUrl",
}

export enum AssetTransferResourceFields {
  content = "content",
  ownerGuid = "ownerGuid",
  userID = "userID",
  fixedProductID = "fixedProductID",
  rootPath = "rootPath",
  mailUrl = "mailUrl",
}

export enum AsseInboxModalResourceFields {
  rootPath = "rootPath",
  agreement = "agreement",
  ownerID = "ownerID",
  ownerGuid = "ownerGuid",
  userID = "userID",
  offerDate = "offerDate",
  offerQuantity = "offerQuantity",
  offerVault = "offerVault",
  offerRelationShip = "offerRelationShip",
  offerProductTitle = "offerProductTitle",
  offerProductType = "offerProductType",
  offerPlatform = "offerPlatform",
  offerType = "offerType",
  offerPeriod = "offerPeriod",
  agreementName = "agreementName",
  agreementDescription = "agreementDescription",
  agreementFencing = "agreementFencing",
  agreementCountries = "agreementCountries",
  promiseBatchSize = "promiseBatchSize",
  promiseCurrentAvailable = "promiseCurrentAvailable",
  promiseMaxQuantity = "promiseMaxQuantity",
  promiseStockLimit = "promiseStockLimit",
  offerIsPromise = "offerIsPromise",
}

export enum AssetOutboxModalResourceFields {
  Date = "Date",
  Quantity = "Quantity",
  Vault = "Vault",
  Relationship = "Relationship",
  Name = "Name",
  Description = "Description",
  GeofencingString = "GeofencingString",
  BlacklistingAllowed = "BlacklistingAllowed",
  VisibilityTypeID = "VisibilityTypeID",
  ExtractionTypeID = "ExtractionTypeID",
  Name1 = "Name1",
  KeyProviderID = "KeyProviderID",
  Type = "Type",
}

export enum AssetPromisedModalResourceFields {
  ReceiverProduct = "ReceiverProduct",
  Agreement = "Agreement",
  KeyProvider = "KeyProvider",
  Owner = "Owner",
  Batchsize = "Batchsize",
  ValidityFrom = "ValidityFrom",
  Stocklimit = "Stocklimit",
  ValidityTo = "ValidityTo",
  MaxQuantity = "MaxQuantity",
  IsDisabledByRelationship = "IsDisabledByRelationship",
  LocalCount = "LocalCount",
}

export enum AssetPromisesModalResourceFields {
  Product = "Product",
  Agreement = "Agreement",
  KeyProvider = "KeyProvider",
  Relationship = "Relationship",
  Batchsize = "Batchsize",
  ValidityFrom = "ValidityFrom",
  Stocklimit = "Stocklimit",
  ValidityTo = "ValidityTo",
  MaxQuantity = "MaxQuantity",
  IsActive = "IsActive",
  IsDisabledByOwner = "IsDisabledByOwner",
  IsDisabledByRelationship = "IsDisabledByRelationship",
  LocalCount = "LocalCount",
  RemoteCount = "RemoteCount",
}

export enum RelationshipDeleteModalResourceFields {
  Status = "Status",
  ActiveSince = "ActiveSince",
  Vault = "Vault",
  Company = "Company",
  OwnerNames = "OwnerNames",
  OwnerEmails = "OwnerEmails",
}

export enum RelationshipInviteModalResourceFields {
  FromName = "FromName",
  FromEmail = "FromEmail",
  ToName = "ToName",
  ToEmail = "ToEmail",
}

export enum RelationshipPendingModalResourceFields {
  Status = "Status",
  ActiveSince = "ActiveSince",
  Vault = "Vault",
  Company = "Company",
  EmailAddress = "EmailAddress",
}

export enum TransactionsResourceFields {
  rootPath = "rootPath",
  ownerGuid = "ownerGuid",
  transactionDetailUrl = "transactionDetailUrl",
}

export enum TransactionDetailResourceFields {
  rootPath = "rootPath",
  ownerGuid = "ownerGuid",
  transactionDetailUrl = "transactionDetailUrl",
  product = "product",
}

export enum SearchFields {
  Filter_Product = "Filter_Product",
  Filter_ProductType = "Filter_ProductType",
  Filter_Vault = "Filter_Vault",
  Filter_TransactionType = "Filter_TransactionType",
  Filter_Search = "Filter_Search",
  Filter_Period = "Filter_Period",
  Filter_StartDate = "Filter_StartDate",
  Filter_EndDate = "Filter_EndDate",
}

export enum AccountFields {
  CurrentUserDisplayname = "CurrentUserDisplayname",
  CurrentUserRole = "CurrentUserRole",
  CurrentUserEmail = "CurrentUserEmail",
  CurrentUserIsAuthenticated = "CurrentUserIsAuthenticated",
  CurrentUserReceiveMailNotificationsLabel = "CurrentUserReceiveMailNotificationsLabel",
  CurrentUserIsActive = "CurrentUserIsActive",
  VaultName = "VaultName",
  VaultGUID = "VaultGUID",
  CompanyName = "CompanyName",
  Country = "Country",
  Timezone = "Timezone",
}

export enum CerticiateAddFields {
  rootPath = "rootPath",
  ownerID = "ownerID",
  userID = "userID",
}

export enum TableGridFields {
  ID = "ID",
  GUID = "GUID",
  SKU = "SKU",
  PackShotImageURL = "PackShotImageURL",
  KeyProviderImageURL = "KeyProviderImageURL",
  Image = "Image",
  PlatformDRM = "Platform (DRM)",
  VaultImageURL = "VaultImageURL",
  Action_ConnectProduct = "Action_ConnectProduct",
  Action_DisconnectProduct = "Action_DisconnectProduct",
  Connected = "Connected",
  IsConnected = "IsConnected",
  PackshotID = "PackshotID",
}

export enum BlacklistingsResourceFields {
  rootPath = "rootPath",
  ownerGuid = "ownerGuid",
}

export enum AccountAuthenticatorResourceFields {
  content = "content",
  ownerID = "ownerID",
  userID = "userID",
  rootPath = "rootPath",
  qrBase64 = "qrBase64",
  mailAcceptURL = "mailAcceptURL",
  mailRejectURL = "mailRejectURL",
}

export enum ActivityResourceFields {
  rootPath = "rootPath",
  services = "services",
  ownerGuid = "ownerGuid",
}

export enum TransactionDetailOwnerResourceFields {
  rootPath = "rootPath",
  ownerGuid = "ownerGuid",
  transaction = "transaction",
  transactionOwner = "transactionOwner",
  receiverID = "receiverID",
  senderID = "senderID",
  typeID = "typeID",
  created = "created",
}

export enum RoleNameFields {
  superAdmin = "Super admin",
  editor = "Editor",
  viewer = "Viewer",
  owner = "Owner",
  publisher = "Publisher",
  distributor = "Distributor",
  retailer = "Retailer",
}

export enum OwnerRoleFields {
  publisher = 1,
  distributor = 2,
  retailer = 3,
}

export enum PageTypeFields {
  priceList = 1,
  promotion = 2,
}

export enum PricingModelFields {
  revenueShareModel = "RevenueShareModel",
  costPrice = "CostPrice",
}

export enum RegionModelFields {
  system = "System",
}

export enum RegionOwnerModelFields {
  my = "My",
  system = "System",
}

export enum ExtractTypeModelFields {
  Email = "Email",
  AuthenticatorAppOrEmail = "AuthenticatorAppOrEmail",
  AuthenticatorApp = "AuthenticatorApp",
}

export enum PathModelFields {
  sandbox = "sandbox",
  tst = "tst",
  acc = "acc",
  localhost = "localhost",
  prod = "prod",
}

export enum RuleModelFields {
  edition = "Edition",
  provider = "Provider",
  region = "Region",
}

export enum ProductAssetTypeFields {
  ProductImage = 1,
  WebAsset = 2,
  TrailerLink = 3,
  Screenshot = 4,
  OtherImage = 5,
  OtherImageLocalized = 6,
}